angular.module('app').filter('product', function () {
    return function (product) {
        var text;

        if (_.isUndefined(product)) return text;

        switch (product) {

            case "CONCIERGE":
                text = "Concierge";
                break;
            case "PASSENGER_IOS":
                text = "iOS Passenger App";
                break;
            case "PASSENGER_ANDROID":
                text = "Android Passenger App";
                break;
            case "WEB":
                text = "Web";
                break;
            case "PHONE":
                text = "Phone";
                break;
            case "DRIVER_IOS":
                text = "ingogo FLAG";
                break;
            case "DRIVER_ANDROID":
                text = "ingogo FLAG";
                break;
            default:
                text = "n/a";
        }

        return text;

    };
});